<template>
  <FveForm class="user-edit form-base">

    <div class="block-1">
      <FveImagePreview
        :field="field.profilePhoto"
        label=""
        name="image"
        class="user-edit__image"
      />
    </div>

    <div class="block-2">
      <div class="section">
        <h3 id="loginData">Login Data</h3>
        <div class="row">
          <div class="form-group">
            <div style="display: inline-block; width: 70%;">
            
<!--              <div class="static-field">-->
<!--                <span class="static-field__label">Login</span>-->
<!--                <span class="static-field__content">{{ field.login.initValue }}@zombty.com</span>-->
<!--              </div>-->
              <FveText
                  readonly
                  :field="field.login"
                  label="Login"
              />
            </div>
            <div style="display: inline-block; width: 30%; padding-left: 10px;">@zombty.com</div>
          </div>
        </div>
        <div class="user-edit__form-section">
          <div class="col-6">
            <FveText
                :field="field.firstName"
                label="First Name"
            />
          </div>
          <div class="col-6">
            <FveText
                :field="field.lastName"
                label="Last Name"
            />
          </div>
          <div class="col-6" style="display: none !important;">
            <FveEmail
                :field="field.mail"
                label="E-mail"
                placeholder="name@mail.com"
            />
          </div>
          <div class="col-6" style="display: none !important;">
            <FveText
                :field="field.password"
                label="E-mail"
                placeholder="name@mail.com"
            />
          </div>
          <div class="col-6">
            <FveEmail
                :field="field.additionalMail"
                label="Additional E-mail"
                placeholder="name@mail.com"
            />
          </div>
        </div>
      </div>

      <div class="section">
        <h3 id="personalInfo">Personal Info</h3>
        <div class="row">
          <div class="form-group">
            <FveDatePicker
                :field="field.birthday"
                label="Birthdate"
            />
          </div>
<!--          <div class="col-12">Gender [SELECT]</div>-->
          <div class="form-group">
            <FveText
                :field="field.familyStatus"
                label="Family Status"
            />
          </div>
          <div class="form-group">
            <FveTextarea
                :field="field.hobbiesAndInterests"
                label="Hobbies and Interests"
            />
          </div>
        </div>

      <div class="section">
        <h3 id="companyAndLocation">Company and Location</h3>
        <div class="row">
          <div class="form-group">
            <FveText
                :field="field.companyName"
                label="Company Name"
            />
          </div>
          <div class="form-group">
            <FveText :field="field.countryOfResidence" label="Country of Residence"/>
          </div>
        </div>
      </div>
    </div>
      <div class="btn-group">
        <button type="button" @click="$emit('close', { action: 'CLOSE' })" class="btn btn--outline">Return</button>
        <button type="button" @click="submit" class="btn btn--primary">Save</button>
      </div>

    </div>

  </FveForm>
</template>

<script>

import FveMixinForm from '@fve/Mixin/FveMixinForm';

import FveImagePreview from "@fve/Element/File/FveImagePreview";
import FveEmail from '@fve/Element/Text/FveEmail';
import FveText from "@fve/Element/Text/FveText";
import FveTextarea from "@fve/Element/Text/FveTextarea";
import FveDatePicker from "@fve/Element/Date/FveDatePicker";

export default {
  mixins: [
    FveMixinForm
  ],
  data() {
    return {}
  },
  components: {
    FveDatePicker,
    FveTextarea,
    FveImagePreview,
    FveText,
    FveEmail,
  },
  methods: {
    formSchema() {
      return {
        profilePhoto: {
          type: FileClass,
          default: () => { return new FileClass(); },
        },
        firstName: {
          type: String,
          default() { return ''; },
          field: {
            required: true,
          }
        },
        lastName: {
          type: String,
          default() { return ''; },
          field: {
            required: true,
          }
        },
        login: {
          type: String,
          default() { return ''; },
          field: {
            required: true,
          }
        },
        password: {
          type: String,
          default: () => { return ''; },
        },
        mail: {
          type: String,
          default: () => {
            return '';
          }
        },
        additionalMail: {
          type: String,
          default: () => {
            return '';
          }
        },
        //
        birthday: {
          type: DateTime,
          default: () => {
            return null;
          }
        },
        familyStatus: {
          type: String,
          default: () => {
            return '';
          }
        },
        hobbiesAndInterests: {
          type: String,
          default: () => {
            return '';
          }
        },
        countryOfResidence: {
          type: String,
          default: () => {
            return '';
          }
        },
        companyName: {
          type: String,
          default: () => {
            return '';
          }
        },
        communitiesEntity: {
          type: Array,
          default: () => { return [];}
        },
        gender: {
          type: String,
          default: () => {
            return '';
          }
        },
      };
    },
  }

};

</script>

<style lang="scss" scoped>

.user-edit {
  width: 100%;
  padding: 40px 72px;
  display: flex;

  .block-1,
  .block-2 {
    display: flex;
    flex-direction: column;
  }

  .block-2 {
    flex: 1 1 auto;
    max-width: 536px;
  }

}

.section {
  margin-bottom: 32px;

  & > h3 {
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.45;
    color: var(--color-gray-09);
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

}

.col-6 {
  padding: 0 8px;
}

.user-edit__image {
  @include icon-size(10);
  border-radius: 100%;
  margin-top: 58px;
  margin-right: 16px;
}

.user-edit__form-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1rem;
  margin: 0 -8px;
  ::v-deep(.fve-control > input) {
    background-color: transparent;
    border: 1px solid var(--schedule-border-color);
  }
}

.form-group {
  margin-bottom: 1rem;
}

.btn-group {
  display: flex;
  justify-content: flex-end;
  margin: 0 -8px;
  & > .btn {
    margin: 0 8px;
    min-width: 148px;
  }
}

.static-field {
  display: flex;
  flex-direction: column;
  
  .static-field__label {
    font-size: 0.875rem;
    line-height: 1.45;
    color: var(--color-gray-06);
  }
  
  .static-field__content {
    margin-top: 4px;
  }
  
}

</style>
