<template>
  
  <div>
    <div class="th-px" style="margin: 0 0 24px">
      <FveForm class="form-auth form-base row">
        <div class="form-group">
          <FvePassword
              :field="field.oldPassword"
              label="Old password"
              placeholder=""
          />
        </div>
        <div class="form-group">
          <FvePassword
              :field="field.newPassword"
              label="New password"
              placeholder=""
          />
        </div>
      </FveForm>
    </div>
    <div class="btn-group th-px">
      <button class="btn btn--outline" @click.prevent="close">Discard</button>
      <button class="btn btn--primary" @click.prevent="submit">Save</button>
    </div>
  </div>
  
</template>

<script>

import FveMixinForm    from '@fve/Mixin/FveMixinForm';

import FvePassword     from '@fve/Element/Text/FvePasswordShowPass';

export default {
  name: "UserPasswordEditForm",
  mixins: [
    FveMixinForm
  ],
  components: {
    FvePassword,
  },
  methods: {
    close(){
      this.$emit('close');
    },
    formSchema() {
      return {
        oldPassword: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
          }
        },
        newPassword: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
          }
        },
      };
    },
  }

};

</script>

<style lang="scss" scoped>

.form-group {
  margin-bottom: 2rem;
}
</style>
