<template>
  <UserPasswordEditForm
    @submit="submitAction"
    ref="form"
  />
</template>

<script>

import UserPasswordEditForm from '@user/page/_component/UserPasswordEditForm';

export default {
  name: 'UserProfilePasswordChange',
  components: {
    UserPasswordEditForm
  },
  methods: {
    submitAction(submitObj) {
      RequestManager.Auth.changeProfilePassword(submitObj).then(
          () => {
            this.$emit('success');
          },
          () => {
            for(let key in this.$refs.form.formElement) {
              const fieldElement = this.$refs.form.formElement[key];
              if(fieldElement.field.name === 'oldPassword') {
                fieldElement.fieldSetError('ERROR', 'Not correct password', "ERROR")
              }
            }
            this.$emit('error');
          }
      );
    },
    
  }
};

</script>
