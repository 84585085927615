<template>
  <div class="page-row">
    <div class="user-edit__fields-wrapper">
      <perfect-scrollbar>
        <UserProfileForm :formData="profileInfo" @submit="updateProfile" @close="close"/>
      </perfect-scrollbar>
    </div>
    <div class="user-edit__nav-wrapper">

      <h3>
        <a href="#" @click.prevent="openModalUserPasswordEdit">Change password</a>
      </h3>
      <!--
      <h3>
        <a href="#loginData">Login Data</a>
      </h3>
      <h3>
        <a href="#personalInfo">Personal Info</a>
      </h3>
      <h3>
        <a href="#companyAndLocation">Company and Location</a>
      </h3>
      <h3>
        <a href="#privacy">Privacy</a>
      </h3>
      -->
    </div>
  </div>
</template>

<script>

import UserProfileForm from '@user/page/_component/UserProfileForm';
import UserProfilePasswordChange from '@user/page/UserProfilePasswordChange';
import AuthClass from "@user/class/AuthClass";
import ProfileClass from "@user/class/ProfileClass";

export default {
  name: 'UserProfileEdit',
  components: {
    UserProfileForm
  },
  data() {
    return {
      profileInfo: this.$user.Profile.getProfileObj()
    };
  },
  methods: {


    openModalUserPasswordEdit() {
      //
      let modal = null;
      const closeModal = () => { modal && modal.close(); };
      const props = {
        // data
        // events
        onSuccess: (submitObj) => {
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(UserProfilePasswordChange, props, { group: 'modal', theme: 'community', close: true,});
      modal.then(() => { modal = null; });
    },

    async updateProfile(profileObj) {

      let updateFileSrc = null;
      if(profileObj.profilePhoto && !profileObj.profilePhoto.getId()) {
        let file = profileObj.profilePhoto.getFile();
        if(file) {
          try {
            // TODO: поправить, так чтоб могли видеть все пользователи без авторизационного токена. (img в браузере)
            updateFileSrc = await RequestManager.FileStorage.add({ file: file, shared: true });
            updateFileSrc = updateFileSrc.url;
          } catch (e) {console.error(e);}
        }
      }
      if(profileObj.profilePhoto && profileObj.profilePhoto.getId()) {
        updateFileSrc = profileObj.profilePhoto.getSrc();
      }

      RequestManager.Auth.updateProfileInfo({
        userId: this.$user.Auth.getUserId(),
        userProfileInfo: {
          id:                   this.$user.Auth.getUserId(),
          profilePhoto:         updateFileSrc,
          //
          firstName:            profileObj.firstName,
          lastName:             profileObj.lastName,
          login:                profileObj.login,
          password:             profileObj.password,
          mail:                 profileObj.mail,
          additionalMail:       profileObj.additionalMail,
          birthday:             profileObj.birthday ? profileObj.birthday.format('YYYY-MM-DD') : null,
          familyStatus:         profileObj.familyStatus,
          hobbiesAndInterests:  profileObj.hobbiesAndInterests,
          countryOfResidence:   profileObj.countryOfResidence,
          companyName:          profileObj.companyName,
          communitiesEntity:    profileObj.communitiesEntity,
          gender:               profileObj.gender,
        }
      }).then((res) => {
        USER.Profile.setProfileObj(res);
        this.$dialog.notify.success("Success", "Profile data updated successfully");
      }).catch((err) => {
        this.$dialog.notify.error("Error", "Error trying to update profile data");
        console.error('[USER_EDIT::ERROR]: ', err);
      });
    },
    
    close() {
      this.$router.push({ name: this.$routeName.USER_PROFILE_VIEW });
    }
    
  }
};

</script>

<style lang="scss" scoped>
.ps {
  height: calc(100vh - 72px);
}

.user-edit__fields-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  border-top-right-radius: 8px;
  padding-top: 2px;
  background-color: var(--profile-edit-background);
}

.user-edit__nav-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 296px;
  width: 100%;
  padding: 40px 24px;

  // TODO: Update and fix

  & > h3 {
    font-size: 1rem;
    color: var(--color-gray-07);
    font-weight: 600;
    padding: 8px;
  }

}

.profile-notification {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .profile-notification__header {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 32px;
    line-height: 1.45;
    background-color: var(--color-gray-01);
  }

  .profile-notification__title {
    font-size: 1.375rem;
    font-weight: 700;
    color: var(--color-gray-08);
  }

  .profile-notification__settings-link {
    font-size: 0.875rem;
    color: var(--color-gray-05);
    cursor: pointer;
  }

  .profile-notification__body {
    height: 100%;
  }

}


</style>
